import { updateContentMask } from 'pages/Planner/states/timelines/utils';

type State = {
  timelines: Record<
    number,
    {
      position: number;
      container: HTMLDivElement;
      contentMask: HTMLDivElement;
      content: HTMLDivElement;
    } | null
  >;
  contentWidth: number;
};

const state: State = {
  timelines: {},
  contentWidth: 0,
};

// ------------------------ registers ------------------------

export const registerTimeline = (
  position: number,
  container: HTMLDivElement,
  contentMask: HTMLDivElement,
  content: HTMLDivElement,
): void => {
  state.timelines[position] = {
    position,
    container,
    contentMask,
    content,
  };
};

export const registerInTimelineState = (contentWidth: number): void => {
  state.contentWidth = contentWidth;
};

export const removeTimeline = (position: number): void => {
  state.timelines[position] = null;
};

//---------------------- interface ---------------------------

export const containerWidth = (): number =>
  state.timelines[0] ? state.timelines[0].container.offsetWidth : 0;

export const amountOfTimelines = (): number =>
  Object.values(state.timelines).filter((timeline) => timeline !== null).length;

export const allContainerWidth = (): number => amountOfTimelines() * containerWidth();

export const scrollTimelines = (scroll: number): void => {
  Object.values(state.timelines).forEach((timeline) => {
    if (!timeline) return;
    timeline.container.scrollLeft = scroll;
  });
};

export const updateAllContentMasks = (): void => {
  Object.values(state.timelines).forEach((timeline) => {
    if (!timeline) return;
    updateContentMask(
      timeline.content,
      timeline.contentMask,
      timeline.container,
      amountOfTimelines(),
      timeline.position,
      state.contentWidth,
    );
  });
};
