import styled, { css } from 'styled-components';
import { colors, font } from 'stylesheet';
import { columnSizeSpacing } from 'configuration';

export const Container = styled.div<{
  isWeekendDay: boolean;
  isFirstDayOfMonth: boolean;
}>`
  height: 100%;
  width: ${columnSizeSpacing};
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;

  ${({ isWeekendDay }) =>
    isWeekendDay &&
    css`
      background-color: ${colors.lightBlue};
    `}

  ${({ isFirstDayOfMonth }) =>
    isFirstDayOfMonth &&
    css`
      border-left: 1px solid ${colors.gray0};
    `}
`;

export const TodayContainer = styled.div`
  height: 100%;
  width: 100%;
  border-radius: 50%;
  color: ${colors.white};
  background-color: ${colors.activeBlue};
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Text = styled.span`
  ${font.size8};
`;
