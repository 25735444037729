import React, { RefObject, MouseEvent as ReactMouseEvent } from 'react';

import { replaceProjectHandler } from 'ressources/project/handlers';
import { Project } from 'components/specifics/Timeline/components/ProjectsDisplay/class';
import { ProjectBackend } from 'ressources/project/class';

import { CalendarColor } from 'ressources/calendar/class';
import { DateNoTime } from 'services/date';
import EditableProject from 'components/specifics/Timeline/components/ProjectsDisplay/components/ProjectWithPopin/components/EditableProject';
import {
  getCorrectPositionX,
  getCorrectPositionY,
  getDateFromTimelineAndMousePosition,
  getLineFromY,
} from 'components/specifics/Timeline/components/ProjectsDisplay/animationsCalculations';

interface Props {
  project: Project;
  color: CalendarColor;
  firstDayOfTimeline: DateNoTime;
  projectRef: RefObject<HTMLDivElement>;
  columnsElement: HTMLDivElement;
}

const DragableProject: React.FC<Props> = ({
  project,
  color,
  firstDayOfTimeline,
  projectRef,
  columnsElement,
}) => {
  const getMouseMoveEventListener =
    (
      mouseInitialX: number,
      mouseInitialY: number,
      projectInitialX: number,
      projectInitialY: number,
    ) =>
    (e: MouseEvent) => {
      e.stopPropagation();
      if (!projectRef.current) {
        return;
      }
      const mouseDiffX = e.clientX - mouseInitialX;
      const mouseDiffY = e.clientY - mouseInitialY;

      const projectNewX = projectInitialX + mouseDiffX;
      const projectNewY = projectInitialY + mouseDiffY;

      projectRef.current.style.left = `${projectNewX}px`;
      projectRef.current.style.top = `${projectNewY}px`;
    };

  const getMouseUpEventListener =
    (
      mouseInitialX: number,
      mouseInitialY: number,
      projectInitialX: number,
      projectInitialY: number,
      mouseMoveEventListener: (e: MouseEvent) => void,
    ) =>
    (e: MouseEvent) => {
      e.stopPropagation();
      document.removeEventListener('mousemove', mouseMoveEventListener);

      if (!projectRef.current) {
        return;
      }

      const mouseFinalX = e.clientX;
      const mouseFinalY = e.clientY;

      const mouseDiffX = mouseFinalX - mouseInitialX;
      const mouseDiffY = mouseFinalY - mouseInitialY;

      const projectNewX = projectInitialX + mouseDiffX;
      const projectNewY = projectInitialY + mouseDiffY;

      projectRef.current.style.left = `${getCorrectPositionX(0, projectNewX)}px`;
      projectRef.current.style.top = `${getCorrectPositionY(0, projectNewY)}px`;

      const newStartDate = getDateFromTimelineAndMousePosition({
        firstDayOfTimeline,
        timelineX: 0, // TODO : à amélirer, pas le même fonctionnement que pour le resize
        mouseX: projectNewX, // TODO : à renomer, ça n'est plus mouseX ici, mais projetX hehe
      });

      const newLine = getLineFromY(0, projectNewY);

      if (project.line != newLine || !DateNoTime.equals(project.start, newStartDate)) {
        const newEndDate = DateNoTime.addDays(newStartDate, project.duration - 1);

        replaceProjectHandler(
          project.id,
          new ProjectBackend(
            project.id,
            project.title,
            newLine,
            newStartDate,
            newEndDate,
            project.calendarId,
          ),
        );
      }
    };

  const onMouseDownDrag = (e: ReactMouseEvent) => {
    e.stopPropagation();
    if (!projectRef.current) {
      return;
    }
    const mouseInitialX = e.clientX;
    const mouseInitialY = e.clientY;
    // slice(0, -2) is here to remove "px"
    const projectInitialX = Number(projectRef.current.style.left.slice(0, -2));
    const projectInitialY = Number(projectRef.current.style.top.slice(0, -2));

    const mouseMoveEventListener = getMouseMoveEventListener(
      mouseInitialX,
      mouseInitialY,
      projectInitialX,
      projectInitialY,
    );

    const mouseUpEventListener = getMouseUpEventListener(
      mouseInitialX,
      mouseInitialY,
      projectInitialX,
      projectInitialY,
      mouseMoveEventListener,
    );

    document.addEventListener('mousemove', mouseMoveEventListener);
    document.addEventListener('mouseup', mouseUpEventListener, { once: true });
  };

  return (
    <EditableProject
      project={project}
      color={color}
      firstDayOfTimeline={firstDayOfTimeline}
      projectRef={projectRef}
      columnsElement={columnsElement}
      onMouseDownDrag={onMouseDownDrag}
    />
  );
};

export default DragableProject;
