import axios, { AxiosResponse } from 'axios';

export const postLogin = async (
  username: string,
  password: string,
): Promise<AxiosResponse<{ token: string }>> => {
  return axios.post(`${process.env.REACT_APP_BACKEND_URL}/login_check`, {
    username,
    password,
  });
};
