import styled from 'styled-components';
import { colors, getSpacing } from 'stylesheet';

const Popin = styled.div`
  box-shadow: 0 0 4px ${colors.gray1};
  background-color: ${colors.white};
  border-radius: ${getSpacing(0.75)};
`;

export default Popin;
