import styled from 'styled-components';
import { colors, getSpacing, outline, projectColorsDetails } from 'stylesheet';
import { ReactComponent as CheckIcon } from 'assets/check.svg';

import { CalendarColor } from 'ressources/calendar/class';
import Popin from 'components/generics/Popin';

export const Container = styled(Popin)`
  padding: ${getSpacing(1)};
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(3, 1fr);
  grid-gap: ${getSpacing(1)};
`;

export const CalendarColorCircle = styled.button<{ color: CalendarColor }>`
  background-color: ${({ color }: { color: CalendarColor }) =>
    projectColorsDetails[color].selectedBackground};
  height: ${getSpacing(2)};
  width: ${getSpacing(2)};
  border-radius: 50%;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  ${outline()};
`;

export const Check = styled(CheckIcon)`
  color: ${colors.white};
  width: ${getSpacing(1)};
`;
