import { addDays, addMonths, addYears, differenceInDays } from 'date-fns';

/*
// Documentation: How to create a date at midnight UTC from a 'YYYY-MM-DD' string

const dateAsString = '2022-01-01';
const dateInLocalTimezone = new Date(`${dateAsString}T00:00:00.000`);

const timezoneOffsetMillis =
  dateInLocalTimezone.getTimezoneOffset() * 60 * 1000;

// Remove timezoneOffset to get UTC midnight with the same day, month and year
// When I do new Date('2022-02-25T00:00:00.000'), if the client computer is in Toto-timezone (Toto-timezone being 2 hours
// ahead of UTC, timezoneOffset is therefore -120), the moment in time that this date represents is 2022-02-24T22:00:00.000
// in UTC time.
const utcMidnightDate = new Date(
  dateInLocalTimezone.getTime() - timezoneOffsetMillis,
);

// Then UTC year, month and day can be obtained this way:
// utcMidnightDate.getUTCFullYear(), utcMidnightDate.getUTCMonth(), utcMidnightDate.getUTCDate()
 */

enum WeekDay {
  SUNDAY,
  MONDAY,
  TUESDAY,
  WEDNESDAY,
  THURSDAY,
  FRIDAY,
  SATURDAY,
}

export class DateNoTime {
  day: number;

  // month in javascript style 0 = january, 11 = december
  month: number;

  year: number;

  constructor(year: number, month: number, day: number) {
    this.year = year;
    this.month = month;
    this.day = day;
  }

  private static fromMidnightDate(date: Date): DateNoTime {
    if (
      date.getHours() !== 0 ||
      date.getMinutes() !== 0 ||
      date.getSeconds() !== 0 ||
      date.getMilliseconds() !== 0
    ) {
      throw new Error('Date provided must be at midnight, date given : ' + date);
    }
    return new DateNoTime(date.getFullYear(), date.getMonth(), date.getDate());
  }

  static fromString(dateAsString: string): DateNoTime {
    try {
      const matches = dateAsString.match(/([0-9]{4})-([0-9]{2})-([0-9]{2})/);
      if (!matches) {
        throw new Error();
      }

      const regexYear = Number(matches[1]);
      const regexMonth = Number(matches[2]) - 1;
      const regexDate = Number(matches[3]);

      if (isNaN(regexYear) || isNaN(regexMonth) || isNaN(regexDate)) {
        throw new Error();
      }

      const dateInLocalTimezone = new Date(`${dateAsString}T00:00:00.000`);

      if (isNaN(dateInLocalTimezone.getTime())) {
        throw new Error();
      }

      if (
        dateInLocalTimezone.getFullYear() !== regexYear ||
        dateInLocalTimezone.getMonth() !== regexMonth ||
        dateInLocalTimezone.getDate() !== regexDate
      ) {
        throw new Error();
      }

      return new DateNoTime(
        dateInLocalTimezone.getFullYear(),
        dateInLocalTimezone.getMonth(),
        dateInLocalTimezone.getDate(),
      );
    } catch (e) {
      throw new Error(`Could not instantiate date ${dateAsString}`);
    }
  }

  private getDate(): Date {
    return new Date(this.year, this.month, this.day);
  }

  private getMidnightUTCTimestamp(): number {
    return Date.UTC(this.year, this.month, this.day);
  }

  static differenceInDays(date1: DateNoTime, date2: DateNoTime): number {
    return differenceInDays(date1.getDate(), date2.getDate());
  }

  static addDays(date: DateNoTime, amount: number): DateNoTime {
    const newDate = addDays(date.getDate(), amount);
    return DateNoTime.fromMidnightDate(newDate);
  }

  static addMonths(date: DateNoTime, amount: number): DateNoTime {
    const newDate = addMonths(date.getDate(), amount);
    return DateNoTime.fromMidnightDate(newDate);
  }

  static addYears(date: DateNoTime, amount: number): DateNoTime {
    const newDate = addYears(date.getDate(), amount);
    return DateNoTime.fromMidnightDate(newDate);
  }

  static isFirstDateNewerThanSecondDate = (
    firstDate: DateNoTime,
    secondDate: DateNoTime,
  ): boolean => firstDate.getMidnightUTCTimestamp() > secondDate.getMidnightUTCTimestamp();

  static getToday = (): DateNoTime => {
    const today = new Date();
    return new DateNoTime(today.getFullYear(), today.getMonth(), today.getDate());
  };

  static getLastDateOfMonth(year: number, month: number): DateNoTime {
    const midnightLastDateOfMonth = new Date(year, month + 1, 0);

    return DateNoTime.fromMidnightDate(midnightLastDateOfMonth);
  }

  static equals(date1: DateNoTime, date2: DateNoTime): boolean {
    return date1.year === date2.year && date1.month === date2.month && date1.day === date2.day;
  }

  isWeekendDate(): boolean {
    const dayOfWeek = this.getDate().getDay();

    return dayOfWeek === WeekDay.SATURDAY || dayOfWeek === WeekDay.SUNDAY;
  }

  isToday(): boolean {
    return DateNoTime.equals(this, DateNoTime.getToday());
  }

  isFirstDateOfMonth(): boolean {
    return this.day === 1;
  }

  getNextDate(): DateNoTime {
    return DateNoTime.addDays(this, 1);
  }

  toString(): string {
    return `${this.year}-${to2Digits(this.month + 1)}-${to2Digits(this.day)}`;
  }
}

export const to2Digits = (num: number): string =>
  num.toLocaleString(undefined, { minimumIntegerDigits: 2 });
