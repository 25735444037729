import styled from 'styled-components';
import { getSpacing, font, colors } from 'stylesheet';
import { Size } from './LogoNameInline';

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  gap: ${getSpacing(1)};
  align-items: center;
`;

export const Logo = styled.img<{ size: Size }>`
  height: ${({ size }) => getSpacing(size)};
`;

export const CompanyName = styled.h1<{ size: Size }>`
  ${({ size }) => {
    switch (size) {
      case Size._16:
        return font.size16;
      case Size._24:
        return font.size24;
    }
  }};
  color: ${colors.black};
`;
