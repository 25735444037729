import { ProjectBackend } from 'ressources/project/class';
import { findCalendar } from 'ressources/calendar/serverState';
import { Calendar } from 'ressources/calendar/class';
import { DateNoTime } from 'services/date';

export enum ProjectStatus {
  NOT_SELECTED = 'not_selected',
  SELECTED = 'selected',
  POPIN = 'popin',
}

export class Project extends ProjectBackend {
  status: ProjectStatus;
  timeline: number;

  constructor(
    id: string,
    title: string,
    line: number,
    start: DateNoTime,
    end: DateNoTime,
    calendarId: string,
    status: ProjectStatus = ProjectStatus.NOT_SELECTED,
    timeline: number,
  ) {
    super(id, title, line, start, end, calendarId);
    this.status = status;
    this.timeline = timeline;
  }

  static fromProjectBackend(
    project: ProjectBackend,
    status: ProjectStatus = ProjectStatus.NOT_SELECTED,
    timeline: number,
  ): Project {
    return new this(
      project.id,
      project.title,
      project.line,
      project.start,
      project.end,
      project.calendarId,
      status,
      timeline,
    );
  }

  toString(): string {
    return `${super.toString()}_${this.status}`;
  }

  getFrontendId(): FrontendId {
    return new FrontendId(this.id, this.timeline);
  }

  getCalendar(): Calendar {
    const calendar = findCalendar(this.calendarId);
    if (!calendar) {
      throw new Error(`Could not find calendar ${this.calendarId}`);
    }

    return calendar;
  }
}

export class FrontendId {
  private id: string;
  private timeline: number;

  constructor(id: string, timeline: number) {
    this.id = id;
    this.timeline = timeline;
  }

  getId(): string {
    return this.id;
  }

  getTimeline(): number {
    return this.timeline;
  }

  equalsFrontendId(id: FrontendId): boolean {
    return this.id === id.getId() && this.timeline === id.getTimeline();
  }
}
