import styled from 'styled-components';
import { colors, getSpacing, noSelect } from 'stylesheet';
import { rowSize } from 'configuration';

export const Border = styled.div`
  overflow: hidden;
  padding: ${getSpacing(1)} 0 0;
  border: 1px solid ${colors.gray0};
  border-radius: ${getSpacing(1)};
`;

export const Container = styled.div`
  overflow: hidden;
  ${noSelect};

  /* Hide scrollbar for Chrome, Safari and Opera */
  &::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
`;

export const ContentMask = styled.div`
  overflow: hidden;
  overflow: clip;
`;

export const Content = styled.div`
  width: fit-content;
  margin-left: 0;
`;

export const MonthsContainer = styled.div`
  display: flex;
`;

export const DaysContainer = styled.div`
  display: flex;
  height: ${getSpacing(2)};
`;

export const ProjectsAndColumnsContainer = styled.div`
  position: relative;
`;

export const ColumnsContainer = styled.div<{ amountOfRows: number }>`
  display: flex;
  height: ${({ amountOfRows }) => getSpacing(amountOfRows * rowSize)};
`;
