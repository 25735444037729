import styled from 'styled-components';
import { getSpacing } from 'stylesheet';

const Form = styled.form`
  width: ${getSpacing(32)};
  display: grid;
  grid-template-columns: 100%;
  grid-row-gap: ${getSpacing(3)};
`;

export default Form;
