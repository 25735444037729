import React, { useEffect, useRef } from 'react';
import { v4 as uuidv4 } from 'uuid';

import { getSelectedCalendar } from 'ressources/calendar/serverState';
import { ProjectBackend } from 'ressources/project/class';
import { addProjectHandler } from 'ressources/project/handlers';
import {
  Project,
  ProjectStatus,
} from 'components/specifics/Timeline/components/ProjectsDisplay/class';

import {
  getDateFromTimelineAndMousePosition,
  getSortedDates,
  updateProjectStyle,
} from 'components/specifics/Timeline/components/ProjectsDisplay/animationsCalculations';
import ProjectDisplay from 'components/specifics/Timeline/components/ProjectsDisplay/components/ProjectDisplay';
import { showPopin } from 'components/specifics/Timeline/components/ProjectsDisplay/selectionState/interface';
import { FrontendId } from 'components/specifics/Timeline/components/ProjectsDisplay/class';
import { DateNoTime } from 'services/date';

interface Props {
  line: number;
  firstDate: DateNoTime;
  firstDayOfTimeline: DateNoTime;
  stopProjectCreation: () => void;
  columnsElement: HTMLDivElement;
  timelinePosition: number;
}

const CreationInProgress: React.FC<Props> = ({
  line,
  firstDate,
  firstDayOfTimeline,
  stopProjectCreation,
  columnsElement,
  timelinePosition,
}) => {
  const ref = useRef<HTMLDivElement>(null);

  const mouseMoveEventListener = (e: MouseEvent) => {
    if (!ref.current) {
      return;
    }

    const secondDate = getDateFromTimelineAndMousePosition({
      firstDayOfTimeline,
      timelineX: columnsElement.getBoundingClientRect().x,
      mouseX: e.clientX,
    });
    const [start, end] = getSortedDates(firstDate, secondDate);

    updateProjectStyle(firstDayOfTimeline, start, end, ref.current);
  };

  const mouseUpEventListener = (e: MouseEvent) => {
    const secondDate = getDateFromTimelineAndMousePosition({
      firstDayOfTimeline,
      timelineX: columnsElement.getBoundingClientRect().x,
      mouseX: e.clientX,
    });
    const [start, end] = getSortedDates(firstDate, secondDate);

    const newProjectId = uuidv4();
    showPopin(new FrontendId(newProjectId, timelinePosition));

    addProjectHandler(
      new ProjectBackend(newProjectId, '', line, start, end, getSelectedCalendar().id),
    );
    stopProjectCreation();
  };

  useEffect(() => {
    document.addEventListener('mousemove', mouseMoveEventListener);
    document.addEventListener('mouseup', mouseUpEventListener);

    return () => {
      document.removeEventListener('mousemove', mouseMoveEventListener);
      document.removeEventListener('mouseup', mouseUpEventListener);
    };
  }, []);

  return (
    <ProjectDisplay
      projectRef={ref}
      firstDayOfTimeline={firstDayOfTimeline}
      project={
        new Project(
          uuidv4(),
          '',
          line,
          firstDate,
          firstDate,
          getSelectedCalendar().id,
          ProjectStatus.SELECTED,
          timelinePosition,
        )
      }
      color={getSelectedCalendar().color}
    />
  );
};

export default CreationInProgress;
