import React, { useEffect, useRef, useState } from 'react';

import { DateNoTime } from 'services/date';

import Month from './components/Month';
import DayLabel from './components/DayLabel';
import Column from './components/Column';

import ProjectsDisplay from './components/ProjectsDisplay';
import { ProjectStatus } from './components/ProjectsDisplay/class';
import { getSelectedProjectStatus } from './components/ProjectsDisplay/selectionState/state';
import { unselectProjects } from './components/ProjectsDisplay/selectionState/interface';
import { runStartProjectCreationFunction } from './components/ProjectsDisplay/animationsEvents';
import { MonthClass } from './components/Month/class';

import { registerTimeline, removeTimeline } from 'pages/Planner/states/timelines/state';

import {
  Border,
  Container,
  ContentMask,
  Content,
  DaysContainer,
  MonthsContainer,
  ProjectsAndColumnsContainer,
  ColumnsContainer,
} from './Timeline.style';

interface Props {
  months: MonthClass[];
  days: DateNoTime[];
  position: number;
}

const lines = Array.from(Array(10).keys());

const Timeline: React.FC<Props> = ({ months, days, position }) => {
  // -------------- Register Timeline in scroll state --------------

  const containerRef = useRef<HTMLDivElement | null>(null);
  const contentMaskRef = useRef<HTMLDivElement | null>(null);
  const contentRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (
      containerRef.current !== null &&
      contentMaskRef.current !== null &&
      contentRef.current !== null
    ) {
      registerTimeline(position, containerRef.current, contentMaskRef.current, contentRef.current);
    }
  }, [containerRef.current, contentMaskRef.current, contentRef.current]);

  useEffect(() => {
    return () => {
      removeTimeline(position);
    };
  }, []);

  // ------------ Register Timeline in scroll state end ------------

  // columnsElement is used by ProjectsDisplay to get timeline x
  const [columnsElement, setColumnsElement] = useState<HTMLDivElement | null>(null);

  return (
    <Border>
      <Container ref={containerRef}>
        <ContentMask ref={contentMaskRef}>
          <Content ref={contentRef}>
            <MonthsContainer>
              {months.map((month) => {
                return <Month key={month.toString()} month={month} />;
              })}
            </MonthsContainer>
            <DaysContainer>
              {days.map((day) => (
                <DayLabel key={day.toString()} day={day} />
              ))}
            </DaysContainer>
            <ProjectsAndColumnsContainer ref={setColumnsElement}>
              <ColumnsContainer
                onMouseDown={(e) => {
                  e.stopPropagation();
                  if (getSelectedProjectStatus() === ProjectStatus.NOT_SELECTED) {
                    runStartProjectCreationFunction(e, position);
                  } else {
                    unselectProjects();
                  }
                }}
                amountOfRows={lines.length}
              >
                {days.map((day) => (
                  <Column key={day.toString()} day={day} />
                ))}
              </ColumnsContainer>
              {columnsElement && (
                <ProjectsDisplay
                  firstDayOfLine={days[0]}
                  columnsElement={columnsElement}
                  timelinePosition={position}
                />
              )}
            </ProjectsAndColumnsContainer>
          </Content>
        </ContentMask>
      </Container>
    </Border>
  );
};

export default React.memo(Timeline);
