import React from 'react';
import { FormattedMessage } from 'react-intl';

import { monthTranslationKeyMapping } from 'services/intl';

import { Container, Text } from './Month.style';
import { MonthClass } from './class';

interface Props {
  month: MonthClass;
}

const Month: React.FC<Props> = ({ month }) => {
  return (
    <Container amountOfDays={month.getAmountOfDays()}>
      <Text>
        <FormattedMessage id={`months.${monthTranslationKeyMapping[month.month]}`} />
      </Text>
    </Container>
  );
};

export default Month;
