import React, { ChangeEventHandler } from 'react';

import { Container, Label, Input } from './TextField.style';

interface Props {
  label: string;
  value: string;
  type: string;
  onChange: ChangeEventHandler<HTMLInputElement>;
}

const TextField: React.FC<Props> = ({ label, value, type, onChange }) => {
  return (
    <Container>
      <Label>{label}</Label>
      <Input type={type} onChange={onChange} value={value} />
    </Container>
  );
};

export default TextField;
