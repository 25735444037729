import React, { Dispatch, SetStateAction, useLayoutEffect } from 'react';
import { offset, shift, useFloating } from '@floating-ui/react-dom';

import { projectColorsDetails } from 'stylesheet';

import { CalendarColor } from 'ressources/calendar/class';

import { Container, CalendarColorCircle, Check } from './CalendarPopin.style';

interface Props {
  popinRef: HTMLElement | null;
  color: CalendarColor;
  setColor: Dispatch<SetStateAction<CalendarColor>>;
}

const projectColorsDetailsKeys: CalendarColor[] = Object.keys(
  projectColorsDetails,
) as CalendarColor[];

const CalendarPopin: React.FC<Props> = ({ popinRef, color, setColor }) => {
  const { x, y, reference, floating, strategy } = useFloating({
    placement: 'right-start',
    middleware: [offset(6), shift()],
  });

  useLayoutEffect(() => {
    reference(popinRef);
  }, [popinRef]);

  return (
    <Container
      ref={floating}
      style={{
        position: strategy,
        top: y ?? '',
        left: x ?? '',
      }}
      onMouseDown={(e) => {
        // prevent from running Timeline and Planner on mouseDown
        e.stopPropagation();
      }}
    >
      {projectColorsDetailsKeys.map((calendarColor) => (
        <CalendarColorCircle
          key={calendarColor}
          color={calendarColor}
          onClick={() => {
            setColor(calendarColor);
          }}
          onKeyDown={(e) => {
            if (e.key == 'Enter' && color !== calendarColor) {
              e.stopPropagation();
            }
          }}
        >
          {color === calendarColor && <Check />}
        </CalendarColorCircle>
      ))}
    </Container>
  );
};

export default CalendarPopin;
