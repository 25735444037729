import { initializeBoards } from './board/interface';
import { initializeCalendars } from './calendar/interface';
import { initializeProjects } from './project/handlers';

export const fetchPlannerData = (): void => {
  initializeBoards();
  (async () => {
    await initializeCalendars();
  })();
  initializeProjects();
};
