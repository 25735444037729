import styled from 'styled-components';
import { colors, font, getSpacing, outline } from 'stylesheet';

const Button = styled.button`
  border-radius: ${getSpacing(0.5)};
  height: ${getSpacing(4)};
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: ${colors.activeBlue};
  color: ${colors.white};
  ${font.size14};
  cursor: pointer;
  ${outline()};
`;

export default Button;
