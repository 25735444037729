import { createProject, deleteProject, fetchProjects, updateProject } from './client';
import { addProject, removeProject, replaceProject, updateProjects } from './serverState';
import { ProjectBackend } from 'ressources/project/class';

export const updateProjectsWithBackendHandler = async (): Promise<void> => {
  const newProjects = await fetchProjects();
  updateProjects(newProjects);
};

export const initializeProjects = updateProjectsWithBackendHandler;

export const addProjectHandler = async (project: ProjectBackend): Promise<void> => {
  addProject(project);
  await createProject(project);
  await updateProjectsWithBackendHandler();
};

export const replaceProjectHandler = async (
  oldProjectId: string,
  newProject: ProjectBackend,
): Promise<void> => {
  replaceProject(oldProjectId, newProject);
  await updateProject(oldProjectId, newProject);
  await updateProjectsWithBackendHandler();
};

export const deleteProjectHandler = async (projectId: string): Promise<void> => {
  removeProject(projectId);
  await deleteProject(projectId);
  await updateProjectsWithBackendHandler();
};
