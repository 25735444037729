import React, { MouseEventHandler, RefObject, MouseEvent as ReactMouseEvent } from 'react';

import {
  Project,
  ProjectStatus,
} from 'components/specifics/Timeline/components/ProjectsDisplay/class';
import { CalendarColor } from 'ressources/calendar/class';
import { DateNoTime } from 'services/date';

import {
  TransparentContainer,
  Container,
  LeftGrip,
  RightGrip,
  Title,
} from './ProjectDisplay.style';
import {
  selectProject,
  showPopin,
} from 'components/specifics/Timeline/components/ProjectsDisplay/selectionState/interface';

interface Props {
  project: Project;
  color: CalendarColor;
  firstDayOfTimeline: DateNoTime;
  projectRef: RefObject<HTMLDivElement>;
  onMouseDownLeft?: (e: ReactMouseEvent) => void;
  onMouseDownRight?: (e: ReactMouseEvent) => void;
  onMouseDownDrag?: (e: ReactMouseEvent) => void;
}

const ProjectDisplay: React.FC<Props> = ({
  project,
  color,
  firstDayOfTimeline,
  projectRef,
  onMouseDownLeft,
  onMouseDownRight,
  onMouseDownDrag,
}) => {
  const daysBeforeProject = DateNoTime.differenceInDays(project.start, firstDayOfTimeline);

  const onMouseDown: MouseEventHandler = (e: ReactMouseEvent) => {
    e.stopPropagation();
    switch (project.status) {
      case ProjectStatus.NOT_SELECTED:
        selectProject(project.getFrontendId());
        break;
      case ProjectStatus.SELECTED:
        showPopin(project.getFrontendId());
        break;
      case ProjectStatus.POPIN:
        selectProject(project.getFrontendId());
        break;
    }
    if (onMouseDownDrag) {
      onMouseDownDrag(e);
    }
  };

  return (
    <TransparentContainer
      ref={projectRef}
      line={project.line}
      daysBeforeProject={daysBeforeProject}
      duration={project.duration}
      onMouseDown={onMouseDown}
    >
      <Container color={color} status={project.status}>
        <LeftGrip onMouseDown={onMouseDownLeft} />
        <Title color={color} status={project.status}>
          {project.title}
        </Title>
        <RightGrip onMouseDown={onMouseDownRight} />
      </Container>
    </TransparentContainer>
  );
};

export default ProjectDisplay;
