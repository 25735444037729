import React, { useState } from 'react';

import { addStartProjectCreationFunction } from 'components/specifics/Timeline/components/ProjectsDisplay/animationsEvents';
import {
  getLineFromY,
  getDateFromTimelineAndMousePosition,
} from 'components/specifics/Timeline/components/ProjectsDisplay/animationsCalculations';

import CreationInProgress from './components/CreationInProgress';
import { DateNoTime } from 'services/date';

interface Props {
  firstDayOfLine: DateNoTime;
  columnsElement: HTMLDivElement;
  timelinePosition: number;
}

const CreationInitialisation: React.FC<Props> = ({
  firstDayOfLine,
  columnsElement,
  timelinePosition,
}) => {
  const [isCreatingProject, setIsCreatingProject] = useState(false);
  const [line, setLine] = useState<number | null>(null);
  const [firstDate, setFirstDate] = useState<DateNoTime | null>(null);

  addStartProjectCreationFunction((e) => {
    setIsCreatingProject(true);
    setLine(getLineFromY(columnsElement.getBoundingClientRect().y, e.clientY));
    setFirstDate(
      getDateFromTimelineAndMousePosition({
        firstDayOfTimeline: firstDayOfLine,
        timelineX: columnsElement.getBoundingClientRect().x,
        mouseX: e.clientX,
      }),
    );
  }, timelinePosition);

  if (!isCreatingProject || !firstDate || null === line) {
    return null;
  }

  return (
    <CreationInProgress
      columnsElement={columnsElement}
      line={line}
      firstDate={firstDate}
      firstDayOfTimeline={firstDayOfLine}
      stopProjectCreation={() => {
        setIsCreatingProject(false);
      }}
      timelinePosition={timelinePosition}
    />
  );
};

export default CreationInitialisation;
