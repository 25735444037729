import styled from 'styled-components';
import { font, getSpacing, colors } from 'stylesheet';
import Link from 'components/generics/Link';

export const SuccessMessage = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: ${getSpacing(0.5)};
  ${font.size16};
`;

export const SuccessLink = styled(Link)`
  ${font.size16};
`;

export const Error = styled.p`
  color: ${colors.red};
  ${font.size12};
`;

export const TextContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: ${getSpacing(0.5)};
`;

export const Text = styled.p`
  ${font.size12};
`;
