import { Calendar, CalendarColor } from './class';

type State = {
  calendars: Calendar[];
};

const state: State = { calendars: [] };

export const updateCalendars = (newCalendars: Calendar[]): void => {
  state.calendars = newCalendars;
};

export const getSelectedCalendar = (): Calendar =>
  findCalendarWithColor(CalendarColor.blue) as Calendar;

export const findCalendar = (calendarId: string): Calendar | undefined => {
  return state.calendars.find((calendar) => calendar.id === calendarId);
};

export const findCalendarWithColor = (color: CalendarColor): Calendar | undefined => {
  return state.calendars.find((calendar) => calendar.color === color);
};
