import { ProjectBackendRaw } from 'ressources/project/client';
import { DateNoTime } from 'services/date';

export class ProjectBackend {
  id: string;
  title: string;
  line: number;
  start: DateNoTime;
  end: DateNoTime;
  duration: number;
  calendarId: string;

  constructor(
    id: string,
    title: string,
    line: number,
    start: DateNoTime,
    end: DateNoTime,
    calendarId: string,
  ) {
    this.id = id;
    this.title = title;
    this.line = line;
    this.start = start;
    this.end = end;
    this.calendarId = calendarId;
    this.duration = DateNoTime.differenceInDays(end, start) + 1;
  }

  toString(): string {
    return `line-${this.line}_${this.start.toString()}_${this.end.toString()}`;
  }

  toProjectBackendRow(): ProjectBackendRaw {
    return {
      id: this.id,
      title: this.title,
      line: this.line,
      startDate: this.start.toString(),
      endDate: this.end.toString(),
      calendarId: this.calendarId,
    };
  }
}
