import axios, { AxiosError, AxiosResponse } from 'axios';
import { getToken } from 'services/authentication/authentication';
import { PATHS } from 'App';
import { NavigateFunction } from 'react-router-dom';

const config = (token: string | null) => ({
  headers: {
    Authorization: `Bearer ${token}`,
  },
});

export const get = async <T>(url: string): Promise<AxiosResponse<T>> => {
  return axios.get(url, config(getToken())).catch(onError);
};

export const post = async <T, U>(url: string, data: T): Promise<AxiosResponse<U>> => {
  return axios.post(url, data, config(getToken())).catch(onError);
};
export const put = async <T, U>(url: string, data: T): Promise<AxiosResponse<U>> => {
  return axios.put(url, data, config(getToken())).catch(onError);
};

export const deleteRequest = async (url: string): Promise<AxiosResponse<void>> => {
  return axios.delete(url, config(getToken())).catch(onError);
};

const onError = (e: AxiosError) => {
  if (e.response?.status === 401) {
    navigate(PATHS.SIGN_IN);
  }

  throw e;
};

let navigate: NavigateFunction = () => {
  return;
};

export const addNavigateFunction = (navigateFunction: NavigateFunction): void => {
  navigate = navigateFunction;
};
