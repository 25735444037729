import axios from 'axios';
import { v4 as uuidv4 } from 'uuid';

export const postUser = async (email: string, password: string): Promise<void> => {
  await axios.post(`${process.env.REACT_APP_BACKEND_URL}/users`, {
    id: uuidv4(),
    email,
    password,
  });
};
