import React from 'react';

import { Container } from './Column.style';
import { DateNoTime } from 'services/date';

interface Props {
  day: DateNoTime;
}

const Column: React.FC<Props> = ({ day }) => {
  return (
    <Container isWeekendDay={day.isWeekendDate()} isFirstDayOfMonth={day.isFirstDateOfMonth()} />
  );
};

export default Column;
