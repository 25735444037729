import React, { RefObject, MouseEvent as ReactMouseEvent } from 'react';

import { replaceProjectHandler } from 'ressources/project/handlers';
import { Project } from 'components/specifics/Timeline/components/ProjectsDisplay/class';
import { ProjectBackend } from 'ressources/project/class';

import {
  getDateFromTimelineAndMousePosition,
  getSortedDates,
  updateProjectStyle,
} from 'components/specifics/Timeline/components/ProjectsDisplay/animationsCalculations';
import ProjectDisplay from 'components/specifics/Timeline/components/ProjectsDisplay/components/ProjectDisplay';
import { CalendarColor } from 'ressources/calendar/class';
import { selectProject } from 'components/specifics/Timeline/components/ProjectsDisplay/selectionState/interface';
import { DateNoTime } from 'services/date';

interface Props {
  project: Project;
  color: CalendarColor;
  firstDayOfTimeline: DateNoTime;
  projectRef: RefObject<HTMLDivElement>;
  columnsElement: HTMLDivElement;
  onMouseDownDrag?: (e: ReactMouseEvent) => void;
}

const EditableProject: React.FC<Props> = ({
  project,
  color,
  firstDayOfTimeline,
  projectRef,
  columnsElement,
  onMouseDownDrag,
}) => {
  enum Side {
    LEFT = 'left',
    RIGHT = 'right',
  }

  const getMouseMoveEventListener = (side: Side) => {
    return (e: MouseEvent) => {
      if (!projectRef.current) {
        return;
      }

      const firstDate = side === Side.LEFT ? project.end : project.start;

      const secondDate = getDateFromTimelineAndMousePosition({
        firstDayOfTimeline,
        timelineX: columnsElement.getBoundingClientRect().x,
        mouseX: e.clientX,
      });

      const [start, end] = getSortedDates(firstDate, secondDate);

      updateProjectStyle(firstDayOfTimeline, start, end, projectRef.current);
    };
  };

  const leftMouseMoveEventListener = getMouseMoveEventListener(Side.LEFT);
  const rightMouseMoveEventListener = getMouseMoveEventListener(Side.RIGHT);

  const getMouseUpEventListener = (side: Side) => {
    return (e: MouseEvent) => {
      document.removeEventListener('mousemove', leftMouseMoveEventListener);
      document.removeEventListener('mousemove', rightMouseMoveEventListener);

      if (!projectRef.current) {
        return;
      }

      const firstDate = side === Side.LEFT ? project.end : project.start;

      const secondDate = getDateFromTimelineAndMousePosition({
        firstDayOfTimeline,
        timelineX: columnsElement.getBoundingClientRect().x,
        mouseX: e.clientX,
      });

      const [start, end] = getSortedDates(firstDate, secondDate);

      selectProject(project.getFrontendId());

      replaceProjectHandler(
        project.id,
        new ProjectBackend(project.id, project.title, project.line, start, end, project.calendarId),
      );
      document.removeEventListener('mouseup', leftMouseUpEventListener);
      document.removeEventListener('mouseup', rightMouseUpEventListener);
    };
  };

  const leftMouseUpEventListener = getMouseUpEventListener(Side.LEFT);
  const rightMouseUpEventListener = getMouseUpEventListener(Side.RIGHT);

  const onMouseDownLeft = (e: ReactMouseEvent) => {
    e.stopPropagation();
    document.addEventListener('mousemove', leftMouseMoveEventListener);
    document.addEventListener('mouseup', leftMouseUpEventListener);
  };

  const onMouseDownRight = (e: ReactMouseEvent) => {
    e.stopPropagation();
    document.addEventListener('mousemove', rightMouseMoveEventListener);
    document.addEventListener('mouseup', rightMouseUpEventListener);
  };

  return (
    <ProjectDisplay
      project={project}
      color={color}
      firstDayOfTimeline={firstDayOfTimeline}
      projectRef={projectRef}
      onMouseDownLeft={onMouseDownLeft}
      onMouseDownRight={onMouseDownRight}
      onMouseDownDrag={onMouseDownDrag}
    />
  );
};

export default EditableProject;
