import styled from 'styled-components';
import { colors, getSpacing } from 'stylesheet';

export const PaddingContainer = styled.div`
  padding: 0 ${getSpacing(2)};
`;

export const Container = styled.div`
  width: 100%;
  height: 3px;
  background-color: ${colors.gray_1};
  position: relative;
`;

export const BarContainer = styled.div`
  position: Absolute;
  height: 100%;
  top: 0;
  left: 0;
`;

export const Bar = styled.div`
  width: 100%;
  height: 100%;
  background-color: ${colors.activeBlue};
`;

export const BarGrab = styled.div`
  position: Absolute;
  cursor: grab;
  width: 100%;
  height: 17px;
  top: -7px;
`;
