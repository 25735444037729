import React, { useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { PATHS } from 'App';

import SignInUpForm from 'components/specifics/SignInUpForm';
import TextField from 'components/generics/Form/TextField';
import Form from 'components/generics/Form/Form';
import Button from 'components/generics/Button';
import Link from 'components/generics/Link';

import { SuccessMessage, SuccessLink, Error, Text, TextContainer } from './SignUp.style';
import { postUser } from './client';

const SignUp: React.FC = () => {
  const intl = useIntl();

  const [email, setEmail] = useState('');
  const [backendError, setBackendError] = useState<boolean>(false);
  const [password, setPassword] = useState('');
  const [success, setSuccess] = useState<boolean>(false);

  return (
    <SignInUpForm>
      {success ? (
        <SuccessMessage>
          <p>
            <FormattedMessage id="sign-up.success" />
          </p>
          <SuccessLink to={PATHS.SIGN_IN}>
            <FormattedMessage id="sign-up.sign-in" />
          </SuccessLink>
        </SuccessMessage>
      ) : (
        <>
          <Form
            onSubmit={async (e) => {
              e.preventDefault();
              try {
                await postUser(email, password);
                setBackendError(false);
                setSuccess(true);
              } catch (e) {
                setBackendError(true);
              }
            }}
          >
            <TextField
              label={intl.formatMessage({ id: 'sign-up.email' })}
              value={email}
              type="text"
              onChange={(e) => {
                setEmail(e.currentTarget.value);
              }}
            />
            <TextField
              label={intl.formatMessage({ id: 'sign-up.password' })}
              type="password"
              value={password}
              onChange={(e) => {
                setPassword(e.currentTarget.value);
              }}
            />
            <Button type="submit">
              <FormattedMessage id="sign-up.sign-up" />
            </Button>
            {backendError && (
              <Error>
                <FormattedMessage id="sign-up.error" />
              </Error>
            )}
          </Form>
          <TextContainer>
            <Text>
              <FormattedMessage id="sign-up.account" />
            </Text>
            <Link to={PATHS.SIGN_IN}>
              <FormattedMessage id="sign-up.sign-in" />
            </Link>
          </TextContainer>
        </>
      )}
    </SignInUpForm>
  );
};

export default SignUp;
