export enum CalendarColor {
  blue = 'blue',
  green = 'green',
  red = 'red',
  yellow = 'yellow',
  orange = 'orange',
  purple = 'purple',
  brown = 'brown',
}

export type Calendar = {
  id: string;
  color: CalendarColor;
  boardId: string;
};
