import React, { ReactElement } from 'react';
import { IntlProvider } from 'react-intl';
import { BrowserRouter } from 'react-router-dom';

import Router from 'Router';

import { flattenMessages } from 'services/intl';
import enMessages from 'translations/en.json';
import frMessages from 'translations/fr.json';

import { BaseStyleProvider } from 'App.style';

export const PATHS = {
  SIGN_IN: '/sign-in',
  SIGN_UP: '/sign-up',
  PLANNER: '/planner',
};

export enum Locale {
  FR = 'fr',
  EN = 'en',
}

const locales = {
  [Locale.FR]: flattenMessages(frMessages),
  [Locale.EN]: flattenMessages(enMessages),
};

function App(): ReactElement {
  return (
    <IntlProvider locale={Locale.FR} messages={locales[Locale.FR]}>
      <BaseStyleProvider>
        <BrowserRouter>
          <Router />
        </BrowserRouter>
      </BaseStyleProvider>
    </IntlProvider>
  );
}

export default App;
