type State = {
  contentWidth: number;
  loadSixPreviousMonths: () => void;
  loadSixNextMonths: () => void;
};

export const state: State = {
  contentWidth: 0,
  loadSixPreviousMonths: (): void => {
    return;
  },
  loadSixNextMonths: (): void => {
    return;
  },
};

// ------------------------ register ------------------------

export const registerInHorizontalScrollHelperState = (
  contentWidth: number,
  loadSixPreviousMonths: () => void,
  loadSixNextMonths: () => void,
): void => {
  state.contentWidth = contentWidth;
  state.loadSixPreviousMonths = loadSixPreviousMonths;
  state.loadSixNextMonths = loadSixNextMonths;
};

// ----------------------- interface -----------------------

export const contentWidth = (): number => {
  return state.contentWidth;
};

export const loadSixPreviousMonths = (): void => {
  state.loadSixPreviousMonths();
};

export const loadSixNextMonths = (): void => {
  state.loadSixNextMonths();
};
