import styled from 'styled-components';
import { colors, font, getSpacing } from 'stylesheet';

export const Container = styled.div`
  display: grid;
  grid-template-columns: 100%;
  grid-row-gap: ${getSpacing(0.5)};
`;

export const Label = styled.p`
  padding-left: ${getSpacing(0.5)};
  ${font.size12}
`;

export const Input = styled.input`
  display: block;
  border: 1px solid ${colors.gray0};
  border-radius: ${getSpacing(0.5)};
  height: ${getSpacing(4)};
  background-color: ${colors.gray_2};
  padding: 0 ${getSpacing(1)};
  ${font.size12};

  :focus {
    border-color: ${colors.gray1};
  }
`;
