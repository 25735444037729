import styled from 'styled-components';
import { getSpacing } from 'stylesheet';

export const Container = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
`;

export const ScrollableContent = styled.div`
  flex-grow: 1;
  overflow: hidden;
`;

export const Content = styled.div`
  padding: ${getSpacing(2)};
  display: grid;
  grid-template-columns: 1fr;
  grid-row-gap: ${getSpacing(2)};
`;
