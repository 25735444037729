import styled from 'styled-components';
import { getSpacing } from 'stylesheet';

export const Container = styled.div`
  padding: ${getSpacing(2)} ${getSpacing(3)};
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;
