import styled from 'styled-components';
import { font, getSpacing } from 'stylesheet';

export const TextContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: ${getSpacing(0.5)};
`;

export const Text = styled.p`
  ${font.size12};
`;
