import styled from 'styled-components';
import { colors, getSpacing } from 'stylesheet';
import { ReactComponent as PlusIcon } from 'assets/plus.svg';
import { ReactComponent as MinusIcon } from 'assets/minus.svg';

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  column-gap: ${getSpacing(1)};
  color: ${colors.gray1};
`;

export const AddTimelineButton = styled.button`
  cursor: pointer;
`;

export const Plus = styled(PlusIcon)`
  height: ${getSpacing(2)};
  width: ${getSpacing(2)};
`;

export const Minus = styled(MinusIcon)`
  height: ${getSpacing(2)};
  width: ${getSpacing(2)};
`;
