import { getSpacing, getPixels } from 'stylesheet';

export const columnSize = 2;

export const columnPixels = getPixels(columnSize);

export const columnSizeSpacing = getSpacing(columnSize);

export const rowSize = 3;

export const rowPixels = getPixels(rowSize);

export const rowSizeSpacing = getSpacing(rowSize);
