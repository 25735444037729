import { DateNoTime } from 'services/date';
import { columnPixels } from 'configuration';
import {
  contentWidth,
  loadSixNextMonths,
  loadSixPreviousMonths,
} from 'pages/Planner/states/horizontalScroll/states/helper/state';
import {
  amountOfTimelines,
  containerWidth,
  scrollTimelines,
} from 'pages/Planner/states/timelines/state';
import { scrollScrollBar } from 'components/specifics/Scrollbar/states/scrollState';

export let scrollState: ScrollState | null = null;

export const initializeOrUpdateScrollState = (firstDay: DateNoTime): void => {
  if (scrollState) {
    scrollState.updateFirstDay(firstDay);
  } else {
    scrollState = new ScrollState(firstDay);
  }
  scrollState.startListeningForScroll();
};

export const relativeScroll = (scroll: number): void => {
  scrollState?.relativeScroll(scroll);
};

export const absoluteScroll = (scroll: number): void => {
  scrollState?.absoluteScroll(scroll);
};

export const currentScroll = (): void => {
  scrollState?.currentScroll();
};

class ScrollState {
  private scrollLeft: number;
  private firstDay: DateNoTime;
  private enabled: boolean;

  constructor(firstDay: DateNoTime) {
    this.scrollLeft = get25DaysBeforeTodayScroll(firstDay);
    this.firstDay = firstDay;
    this.enabled = false;
  }

  updateFirstDay = (firstDay: DateNoTime): void => {
    if (!DateNoTime.equals(this.firstDay, firstDay)) {
      const amountOfDays = DateNoTime.differenceInDays(this.firstDay, firstDay);
      this.scrollLeft += amountOfDays * columnPixels;
      this.firstDay = firstDay;
    }
  };

  //--------------------------------------------------------

  startListeningForScroll = (): void => {
    this.enabled = true;
  };

  private stopListeningForScroll = (): void => {
    this.enabled = false;
  };

  //--------------------------------------------------------

  currentScroll = (): void => {
    this.absoluteScroll(this.scrollLeft);
  };

  relativeScroll = (scroll: number): void => {
    this.absoluteScroll(this.scrollLeft + scroll);
  };

  absoluteScroll = (scroll: number): void => {
    if (!this.enabled) return;
    const min = 0;
    const max = contentWidth() - amountOfTimelines() * containerWidth();

    this.scrollLeft = scroll;

    if (scroll <= min) {
      this.stopListeningForScroll();
      loadSixPreviousMonths();
      return;
    }

    if (scroll >= max) {
      this.stopListeningForScroll();
      loadSixNextMonths();
      return;
    }

    scrollTimelines(scroll);
    scrollScrollBar(scroll);
  };
}

const get25DaysBeforeTodayScroll = (firstDay: DateNoTime): number => {
  const amountOfDays = DateNoTime.differenceInDays(
    DateNoTime.addDays(DateNoTime.getToday(), -25),
    firstDay,
  );

  return amountOfDays * columnPixels;
};
