import styled, { css } from 'styled-components';
import { columnSizeSpacing } from 'configuration';
import { colors } from 'stylesheet';

export const Container = styled.div<{
  isWeekendDay: boolean;
  isFirstDayOfMonth: boolean;
}>`
  height: 100%;
  width: ${columnSizeSpacing};
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;

  ${({ isWeekendDay }) =>
    isWeekendDay &&
    css`
      background-color: ${colors.lightBlue};
    `}

  ${({ isFirstDayOfMonth }) =>
    isFirstDayOfMonth &&
    css`
      border-left: 1px solid ${colors.gray0};
    `}
`;
