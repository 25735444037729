import { MutableRefObject, useEffect, useRef } from 'react';

type State = {
  timelinesContainer: HTMLDivElement | null;
  scrollTop: number;
};

const state: State = {
  timelinesContainer: null,
  scrollTop: 0,
};

const addTimelinesContainer = (element: HTMLDivElement): void => {
  state.timelinesContainer = element;
};

export const useRegisterInVerticalScrollState = (): MutableRefObject<HTMLDivElement | null> => {
  const timelinesContainer = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (timelinesContainer.current) {
      addTimelinesContainer(timelinesContainer.current);
    }
  }, [timelinesContainer]);

  return timelinesContainer;
};

const scrollTop = (scroll: number): void => {
  if (!state.timelinesContainer) {
    return;
  }

  const min = 0;
  const max =
    state.timelinesContainer.scrollHeight - state.timelinesContainer.getBoundingClientRect().height;

  if (scroll <= min) {
    state.timelinesContainer.scrollTo(0, min);
    state.scrollTop = min;

    return;
  }

  if (scroll >= max) {
    state.timelinesContainer.scrollTo(0, max);
    state.scrollTop = max;

    return;
  }

  state.timelinesContainer.scrollTo(0, scroll);
  state.scrollTop = scroll;
};

export const handleScrollTopEvent = (value: number): void => {
  scrollTop(state.scrollTop + value);
};
