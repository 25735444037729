import { Board } from './class';

type State = {
  boards: Board[];
};

const state: State = {
  boards: [],
};

export const getCurrentBoard = (): Board => state.boards[0];

export const updateBoards = (boards: Board[]): void => {
  state.boards = boards;
};
