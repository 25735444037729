import React, {
  Dispatch,
  SetStateAction,
  useLayoutEffect,
  RefObject,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useFloating, offset, shift, flip } from '@floating-ui/react-dom';
import { v4 as uuidv4 } from 'uuid';

import { getCurrentBoard } from 'ressources/board/serverState';

import { Project } from 'components/specifics/Timeline/components/ProjectsDisplay/class';

import { findCalendarWithColor } from 'ressources/calendar/serverState';
import { createCalendar } from 'ressources/calendar/client';
import { ProjectBackend } from 'ressources/project/class';
import { replaceProjectHandler } from 'ressources/project/handlers';
import { CalendarColor } from 'ressources/calendar/class';
import { updateCalendarsWithBackend } from 'ressources/calendar/interface';

import CalendarPopin from './components/CalendarPopin';
import { Container, Header, TitleInput, CalendarColorCircle, PenIcon } from './ProjectPopin.style';

interface Props {
  barRef: RefObject<HTMLDivElement>;
  project: Project;
  title: string;
  setTitle: Dispatch<SetStateAction<string>>;
  color: CalendarColor;
  setColor: Dispatch<SetStateAction<CalendarColor>>;
}

const ProjectPopin: React.FC<Props> = ({ barRef, project, title, setTitle, color, setColor }) => {
  const [displayCalendarPopin, setDisplayCalendarPopin] = useState<boolean>(false);

  const { x, y, reference, floating, strategy, refs } = useFloating({
    placement: 'right',
    middleware: [offset(6), shift({ padding: 8 }), flip()],
  });

  const inputRef = useRef<HTMLInputElement>(null);

  useLayoutEffect(() => {
    reference(barRef.current);
  }, [barRef]);

  useEffect(() => {
    // delay focus to leave time to popin to position itself before focusing
    setTimeout(() => {
      if (inputRef.current) {
        inputRef.current.focus();
      }
    }, 50);
  }, [inputRef]);

  const getCalendarId = async (): Promise<string> => {
    const foundCalendar = findCalendarWithColor(color);

    if (foundCalendar) {
      return foundCalendar.id;
    }

    const newCalendarId = uuidv4();
    await createCalendar({
      id: newCalendarId,
      color: color,
      boardId: getCurrentBoard().id,
    });
    await updateCalendarsWithBackend();

    const createdCalendar = findCalendarWithColor(color);

    if (!createdCalendar) {
      throw new Error('Error while creating new calendar');
    }

    return createdCalendar.id;
  };

  const saveModifications = async (): Promise<void> => {
    if (project.title === title && project.getCalendar().color === color) {
      return;
    }

    const calendarId = await getCalendarId();

    await replaceProjectHandler(
      project.id,
      new ProjectBackend(project.id, title, project.line, project.start, project.end, calendarId),
    );
  };

  // When the popin is closed, the popin component is unmounted. During the unmounting, a useEffect clean up function
  // is called and saves the project modifications (color or title change).
  //
  // The saveModification function is put in a ref that is updated when the title or color changes.
  // This way, the useEffect clean up function that calls the saveModificationFunction always has the lastest title and color.
  //
  // Without this ref, the useEffect clean up function would have the color and title that were there when the useEffect
  // was first run (when the component first mounted).
  // Another way to say it : without this ref, the useEffect clean up function wouldn't have access to the lastest states
  // of color and title.
  const saveModificationsFunction = useRef<(() => Promise<void>) | null>(null);

  useEffect(() => {
    saveModificationsFunction.current = saveModifications;
  }, [saveModifications, title, color]);

  useEffect(() => {
    // The useEffect clean up function mentioned above
    return () => {
      if (saveModificationsFunction.current) {
        saveModificationsFunction.current();
      }
    };
  }, []);

  return (
    <Container
      ref={floating}
      style={{
        position: strategy,
        top: y ?? '',
        left: x ?? '',
      }}
      onClick={(e) => {
        // prevent from running Timeline and Planner on onClick
        e.stopPropagation();
      }}
    >
      <Header>
        <TitleInput
          ref={inputRef}
          type="text"
          name="title"
          value={title}
          onChange={(e) => setTitle(e.currentTarget.value)}
        />
        <CalendarColorCircle
          color={color}
          onClick={() => {
            setDisplayCalendarPopin((value) => !value);
          }}
          onKeyDown={(e) => {
            if (e.key == 'Enter') {
              e.stopPropagation();
            }
          }}
        >
          <PenIcon />
        </CalendarColorCircle>
      </Header>
      {displayCalendarPopin && (
        <CalendarPopin color={color} setColor={setColor} popinRef={refs.floating.current} />
      )}
    </Container>
  );
};

export default ProjectPopin;
