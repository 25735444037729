import { Navigate, Route, Routes, useNavigate } from 'react-router-dom';
import { getIsAuthenticated } from 'services/authentication/authentication';
import SignIn from 'pages/SignIn';
import SignUp from 'pages/SignUp';
import RequireAuth from 'components/wrappers/RequireAuth';
import Planner from 'pages/Planner';
import React from 'react';
import { PATHS } from 'App';
import { addNavigateFunction } from 'services/networking/client';

const Router: React.FC = () => {
  const navigate = useNavigate();
  addNavigateFunction(navigate);

  return (
    <Routes>
      <Route
        path="/"
        element={
          getIsAuthenticated() ? (
            <Navigate replace to={PATHS.PLANNER} />
          ) : (
            <Navigate replace to={PATHS.SIGN_IN} />
          )
        }
      />
      <Route path={PATHS.SIGN_IN} element={<SignIn />} />
      <Route path={PATHS.SIGN_UP} element={<SignUp />} />
      <Route path={PATHS.PLANNER} element={<RequireAuth child={<Planner />} />} />
      <Route path="*" element={<Navigate replace to="/" />} />
    </Routes>
  );
};

export default Router;
