import React from 'react';
import { useNavigate } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';

import { PATHS } from 'App';
import LogoNameInline from 'components/specifics/LogoNameInline';
import { Size } from 'components/specifics/LogoNameInline/LogoNameInline';
import { LinkAsButton } from 'components/generics/Link/Link';
import { removeToken } from 'services/authentication/authentication';

import { Container } from './Header.style';

const Header: React.FC = () => {
  const navigate = useNavigate();

  return (
    <Container>
      <LogoNameInline size={Size._16} />
      <LinkAsButton
        onClick={() => {
          removeToken();
          navigate(PATHS.SIGN_IN);
        }}
      >
        <FormattedMessage id="logout" />
      </LinkAsButton>
    </Container>
  );
};

export default Header;
