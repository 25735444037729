import { MouseEvent } from 'react';

type StartProjectCreationFunction = (e: MouseEvent<HTMLDivElement>) => void;

const startProjectCreationList: Record<number, StartProjectCreationFunction> = {};

export const addStartProjectCreationFunction = (
  startProjectCreationFunction: StartProjectCreationFunction,
  timelinePosition: number,
): void => {
  startProjectCreationList[timelinePosition] = startProjectCreationFunction;
};

export const runStartProjectCreationFunction = (
  e: MouseEvent<HTMLDivElement>,
  timelinePosition: number,
): void => {
  if (startProjectCreationList[timelinePosition]) {
    startProjectCreationList[timelinePosition](e);
  }
};
