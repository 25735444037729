import React, { ReactElement, useEffect } from 'react';
import { Navigate, useNavigate } from 'react-router-dom';

import { PATHS } from 'App';
import { getIsAuthenticated, removeToken } from 'services/authentication/authentication';

interface Props {
  child: ReactElement;
}

const RequireAuth: React.FC<Props> = ({ child }) => {
  const navigate = useNavigate();

  useEffect(() => {
    const checkIfTokenIsValid = () => {
      if (document.visibilityState === 'visible' && !getIsAuthenticated()) {
        removeToken();
        navigate(PATHS.SIGN_IN);
      }
    };

    document.addEventListener('visibilitychange', checkIfTokenIsValid);
    return () => {
      document.removeEventListener('visibilitychange', checkIfTokenIsValid);
    };
  }, []);

  return getIsAuthenticated() ? child : <Navigate to={PATHS.SIGN_IN} />;
};

export default RequireAuth;
