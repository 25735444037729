import { useEffect } from 'react';
import { unselectProjects } from 'components/specifics/Timeline/components/ProjectsDisplay/selectionState/interface';
import { handleScrollTopEvent } from 'pages/Planner/states/verticalScroll/state';
import { relativeScroll } from 'pages/Planner/states/horizontalScroll/state';

type State = {
  x: number;
  y: number;
};

const state: State = {
  x: 0,
  y: 0,
};

const onTouchMove = (e: TouchEvent): void => {
  e.preventDefault();

  unselectProjects();

  const deltaX = e.touches[0].pageX - state.x;
  const deltaY = e.touches[0].pageY - state.y;

  state.x = e.touches[0].pageX;
  state.y = e.touches[0].pageY;

  relativeScroll(-deltaX);
  handleScrollTopEvent(-deltaY);
};

const onTouchStart = (e: TouchEvent) => {
  state.x = e.touches[0].pageX;
  state.y = e.touches[0].pageY;
  document.addEventListener('touchmove', onTouchMove, { passive: false });
};

const onTouchEnd = () => {
  document.removeEventListener('touchmove', onTouchMove);
};

export const useTouch = (): void => {
  useEffect(() => {
    document.addEventListener('touchstart', onTouchStart, { passive: false });
    document.addEventListener('touchend', onTouchEnd);
    return () => {
      document.removeEventListener('touchstart', onTouchStart);
    };
  }, []);
};
