import React, { ReactNode } from 'react';

import LogoNameInline from 'components/specifics/LogoNameInline';
import { Size } from 'components/specifics/LogoNameInline/LogoNameInline';

import { Container, FormContainer } from './SignInUpForm.style';

interface Props {
  children: ReactNode;
}

const SignInUpForm: React.FC<Props> = ({ children }) => {
  return (
    <Container>
      <FormContainer>
        <LogoNameInline size={Size._24} />
        {children}
      </FormContainer>
    </Container>
  );
};

export default SignInUpForm;
