import styled from 'styled-components';
import { columnSize, columnSizeSpacing } from 'configuration';
import { colors, font, getSpacing } from 'stylesheet';

export const Container = styled.div<{ amountOfDays: number }>`
  height: ${columnSizeSpacing};
  width: ${({ amountOfDays }) => getSpacing(amountOfDays * columnSize)};
  padding-left: ${getSpacing(0.5)};
  box-sizing: border-box;
  border-left: 1px solid ${colors.gray0};
  display: flex;
  align-items: center;
`;

export const Text = styled.span`
  left: ${getSpacing(0.5)};
  position: sticky;
  position: -webkit-sticky;
  ${font.size8};
`;
