import { deleteRequest, get, post, put } from 'services/networking/client';

import { ProjectBackend } from './class';
import { DateNoTime } from 'services/date';

export type ProjectBackendRaw = {
  id: string;
  title: string;
  line: number;
  startDate: string;
  endDate: string;
  calendarId: string;
};

export const fetchProjects = async (): Promise<ProjectBackend[]> => {
  const response = await get<ProjectBackendRaw[]>(`${process.env.REACT_APP_BACKEND_URL}/projects`);

  return response.data.map((project: ProjectBackendRaw): ProjectBackend => {
    return new ProjectBackend(
      project.id,
      project.title,
      project.line,
      DateNoTime.fromString(project.startDate),
      DateNoTime.fromString(project.endDate),
      project.calendarId,
    );
  });
};

export const createProject = async (project: ProjectBackend): Promise<void> => {
  await post<ProjectBackendRaw, void>(
    `${process.env.REACT_APP_BACKEND_URL}/projects`,
    project.toProjectBackendRow(),
  );
};

export const updateProject = async (
  oldProjectId: string,
  newProject: ProjectBackend,
): Promise<void> => {
  await put<ProjectBackendRaw, void>(
    `${process.env.REACT_APP_BACKEND_URL}/projects/${oldProjectId}`,
    newProject.toProjectBackendRow(),
  );
};

export const deleteProject = async (projectId: string): Promise<void> => {
  await deleteRequest(`${process.env.REACT_APP_BACKEND_URL}/projects/${projectId}`);
};
