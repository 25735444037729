import { MonthClass } from 'components/specifics/Timeline/components/Month/class';
import { Dispatch, SetStateAction, useEffect, useMemo } from 'react';
import { getDays, getMonths } from 'pages/Planner/utils/daysAndMonths';
import { getPixels } from 'stylesheet';
import { registerInTimelineState } from 'pages/Planner/states/timelines/state';
import { DateNoTime } from 'services/date';
import { updateDimensionsThenScroll } from 'pages/Planner/Planner';
import { registerInHorizontalScrollHelperState } from 'pages/Planner/states/horizontalScroll/states/helper/state';
import { initializeOrUpdateScrollState } from 'pages/Planner/states/horizontalScroll/state';

export const useRegisterHorizontalScrollAndTimelines = (
  months: MonthClass[],
  setMonths: Dispatch<SetStateAction<MonthClass[]>>,
): [days: DateNoTime[], contentWidth: number] => {
  const days = useMemo(() => getDays(months), [months]);
  const contentWidth = getPixels(days.length) * 2;
  const firstDayOfTimeline = days[0];

  const loadSixPreviousMonths = (): void => {
    setMonths((oldMonths) => {
      const additionalMonths = getMonths(
        MonthClass.addMonths(oldMonths[0], -6),
        MonthClass.addMonths(oldMonths[0], -1),
      );

      return [...additionalMonths, ...oldMonths];
    });
  };

  const loadSixNextMonths = (): void => {
    setMonths((oldMonths) => {
      const additionalMonths = getMonths(
        MonthClass.addMonths(oldMonths[oldMonths.length - 1], 1),
        MonthClass.addMonths(oldMonths[oldMonths.length - 1], 6),
      );

      return [...oldMonths, ...additionalMonths];
    });
  };

  // ------------------- shared states registration -------------------

  registerInHorizontalScrollHelperState(contentWidth, loadSixPreviousMonths, loadSixNextMonths);

  registerInTimelineState(contentWidth);

  // ---------- Timelines and scrollbar dimensions and scroll ----------

  // To scroll timelines, the timelines must have been registered previously in the timelines state.
  // The first time the Planner renders, my tests have shown that the useEffect that registers timelines in the
  // <Timeline /> component are played before the useEffect in the Planner. Even with the "if (ref !== null)" condition.
  // Therefore the initial scroll (that will focus on today) will be triggered after the timelines registration and should
  // work properly.

  // The other times this effect is played is when the Planner rerenders which is when :
  // - months are updated (when we have scrolled to le left or right limit)
  // - timelines are added / removed.

  // other than that, the updateDimensionsThenScroll is also called on window resize.

  useEffect(() => {
    initializeOrUpdateScrollState(firstDayOfTimeline);
    updateDimensionsThenScroll();
  });

  return [days, contentWidth];
};
