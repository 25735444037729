import jwt_decode from 'jwt-decode';

import { postLogin } from './client';

const TOKEN_NAME = 'ACCESS_TOKEN';

export const saveToken = async (accessToken: string): Promise<void> =>
  localStorage.setItem(TOKEN_NAME, accessToken);

export const getToken = (): string | null => localStorage.getItem(TOKEN_NAME);

// Warning : this function does not check token validity, only its expiration date.
const isTokenValid = (token: string) =>
  new Date(parseInt(jwt_decode<{ exp: string }>(token).exp) * 1000) > new Date();

export const getIsAuthenticated = (): boolean => {
  const token = getToken();

  return null !== token && isTokenValid(token);
};

export const login = async (username: string, password: string): Promise<boolean> => {
  try {
    const response = await postLogin(username, password);
    await saveToken(response.data.token);
    return true;
  } catch (e) {
    return false;
  }
};

export const removeToken = (): void => {
  localStorage.removeItem(TOKEN_NAME);
};
