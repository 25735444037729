import React, { Dispatch, SetStateAction } from 'react';

import { Container, AddTimelineButton, Minus, Plus } from './AddTimelineButtons.style';

interface Props {
  timelineList: number[];
  setTimelineList: Dispatch<SetStateAction<number[]>>;
}

const AddTimelineButtons: React.FC<Props> = ({ timelineList, setTimelineList }) => {
  const addTimeline = () => {
    setTimelineList((oldList) => {
      if (oldList.length >= 5) {
        return [0, 1, 2, 3, 4];
      }
      return Array.from(Array(oldList.length + 1).keys());
    });
  };

  const removeTimeline = () => {
    setTimelineList((oldList): number[] => {
      if (oldList.length <= 1) {
        return [0];
      }
      return Array.from(Array(oldList.length - 1).keys());
    });
  };
  return (
    <Container>
      {timelineList.length < 5 && (
        <AddTimelineButton onClick={addTimeline}>
          <Plus />
        </AddTimelineButton>
      )}
      {timelineList.length > 1 && (
        <AddTimelineButton onClick={removeTimeline}>
          <Minus />
        </AddTimelineButton>
      )}
    </Container>
  );
};

export default AddTimelineButtons;
