import styled, { css } from 'styled-components';
import { Link as ReactRouterLink } from 'react-router-dom';
import { colors, font } from 'stylesheet';

const linkStyle = css`
  color: ${colors.activeBlue};
  ${font.size12};
  cursor: pointer;
  :hover,
  :focus {
    margin-bottom: -1px;
    border-bottom: 1px solid ${colors.activeBlue};
  }
`;

const Link = styled(ReactRouterLink)`
  ${linkStyle};
`;

export const LinkAsButton = styled.button`
  ${linkStyle};
`;

export default Link;
