import React, { useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';

import { PATHS } from 'App';

import TextField from 'components/generics/Form/TextField';
import SignInUpForm from 'components/specifics/SignInUpForm';
import Button from 'components/generics/Button';
import Link from 'components/generics/Link';
import Form from 'components/generics/Form/Form';

import { login } from 'services/authentication/authentication';

import { TextContainer, Text } from './SignIn.style';

const SignIn: React.FC = () => {
  const intl = useIntl();
  const navigate = useNavigate();

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  return (
    <SignInUpForm>
      <Form
        onSubmit={async (e) => {
          e.preventDefault();
          const isLoginSuccessful = await login(email, password);
          if (isLoginSuccessful) {
            navigate(PATHS.PLANNER);
          }
        }}
      >
        <TextField
          label={intl.formatMessage({ id: 'sign-in.email' })}
          value={email}
          type="text"
          onChange={(e) => {
            setEmail(e.currentTarget.value);
          }}
        />
        <TextField
          label={intl.formatMessage({ id: 'sign-in.password' })}
          type="password"
          value={password}
          onChange={(e) => {
            setPassword(e.currentTarget.value);
          }}
        />
        <Button type="submit">
          <FormattedMessage id="sign-in.sign-in" />
        </Button>
      </Form>
      <TextContainer>
        <Text>
          <FormattedMessage id="sign-in.no-account" />
        </Text>
        <Link to={PATHS.SIGN_UP}>
          <FormattedMessage id="sign-in.sign-up" />
        </Link>
      </TextContainer>
    </SignInUpForm>
  );
};

export default SignIn;
