import {
  getSelectedProjectId,
  getSelectedProjectStatus,
} from 'components/specifics/Timeline/components/ProjectsDisplay/selectionState/state';
import { ProjectStatus } from 'components/specifics/Timeline/components/ProjectsDisplay/class';
import { deleteProjectHandler } from 'ressources/project/handlers';
import {
  unselectProjects,
  updateSelectedProjectStatus,
} from 'components/specifics/Timeline/components/ProjectsDisplay/selectionState/interface';

export const onKeydown = (e: KeyboardEvent): void => {
  const selectProjectId = getSelectedProjectId();
  if ((e.key === 'Backspace' || e.key === 'Delete') && selectProjectId) {
    deleteProjectHandler(selectProjectId.getId());
    unselectProjects();
  }

  if (e.key === 'Escape') {
    unselectProjects();
  }

  if (e.key == 'Enter') {
    const selectedProjectStatus = getSelectedProjectStatus();

    if (selectedProjectStatus === ProjectStatus.NOT_SELECTED) {
      return;
    }

    if (selectedProjectStatus === ProjectStatus.SELECTED) {
      updateSelectedProjectStatus(ProjectStatus.POPIN);
      return;
    }

    if (selectedProjectStatus === ProjectStatus.POPIN) {
      updateSelectedProjectStatus(ProjectStatus.SELECTED);
      return;
    }
  }
};
